import { gql } from '@apollo/client';

export const GET_USERS_QUERY = gql`
  query GetUsers($filter: UserFilter, $sort: UserSortInput, $pageSize: Int, $page: Int) {
    getUsers(filter: $filter, sort: $sort, pageSize: $pageSize, page: $page) {
      users {
        id
        firstName
        lastName
        fullName
        email
        role
        createdAt
        disabled
        talkJsId
      }
      meta {
        currentPage
        isFirstPage
        isLastPage
        nextPage
        pageCount
        previousPage
        totalCount
      }
    }
  }
`;

export const USER_FIELDS_FRAGMENT = gql`
  fragment UserFields on GraphqlUser {
    id
    firstName
    lastName
    email
    fullName
    phoneNumber
    createdAt
    updatedAt
    defaultTab
    talkJsId
    language
    disabled
    emailVerified
    role
    lastLogin
    bio
    profilePicture
    emergencyContact {
      name
      relation
      phoneNumber
    }
  }
`;

export const GET_USER_QUERY = gql`
  query GetUser($getUserId: Int!) {
    getUser(id: $getUserId) {
      ...UserFields
    }
  }
`;

export const GET_USER_BY_TALKJS_ID_QUERY = gql`
  query GetUserByChatProviderId($chatProviderId: String!) {
    getUserByChatProviderId(chatProviderId: $chatProviderId) {
      ...UserFields
    }
  }
`;

export const DISABLE_USER_MUTATION = gql`
  mutation DisableUser($disableUserId: Int!) {
    disableUser(id: $disableUserId) {
      disabled
      id
    }
  }
`;

export const ENABLE_USER_MUTATION = gql`
  mutation EnableUser($enableUserId: Int!) {
    enableUser(id: $enableUserId) {
      disabled
      id
    }
  }
`;

export const ADMIN_CREATE_USER_MUTATION = gql`
  mutation AdminCreateUser($adminCreateUserInput: AdminCreateUserInput!) {
    adminCreateUser(adminCreateUserInput: $adminCreateUserInput) {
      id
      firstName
      lastName
      email
      fullName
      createdAt
      updatedAt
      disabled
      role
    }
  }
`;

export const ADMIN_UPDATE_USER_MUTATION = gql`
  mutation AdminUpdateUser($adminUpdateUserInput: AdminUpdateUserInput!, $adminUpdateUserId: Int!) {
    adminUpdateUser(adminUpdateUserInput: $adminUpdateUserInput, id: $adminUpdateUserId) {
      id
      firstName
      lastName
      email
      fullName
      phoneNumber
      createdAt
      updatedAt
      defaultTab
      talkJsId
      language
      disabled
      emailVerified
      role
    }
  }
`;

export const GET_CUSTOMERS_QUERY = gql`
  query GetCustomers($filters: FiltersInput!) {
    getCustomers(filters: $filters) {
      id
      updatedAt
      createdBy
      createdAt
      firstName
      lastName
      fullName
      phoneNumber
      addresses {
        addressLine1
        addressLine2
        city
        id
        isDefault
        isFromAPI
        state
        updatedAt
        zipCode
        customerId
        createdAt
        country
        place
      }
      users {
        role
        user {
          id
          createdAt
          updatedAt
          email
          fullName
          phoneNumber
        }
      }
    }
  }
`;

export const GET_CUSTOMER_QUERY = gql`
  query GetCustomer($customerId: Float!) {
    getCustomer(customerId: $customerId) {
      id
      firstName
      lastName
      fullName
      Creator {
        fullName
        id
        firstName
        lastName
        AppFeaturesSelected {
          circleMember
          familyPartner
          id
          seniorMember
          userId
        }
      }
      addresses {
        id
        addressLine1
        addressLine2
        city
        customerId
        isDefault
        state
        zipCode
      }
      users {
        role
        user {
          id
          fullName
          createdAt
        }
      }
      createdBy
      createdAt
      updatedAt
      CustomerAdditionalInformation {
        customerId
        gender
        age
        retiredTime
        hobbies
        professionalBackground
        educationalBackground
        relationshipStatus
        otherInformation
        city
        state
        zipCode
      }
    }
  }
`;

export const UPDATE_CUSTOMER_QUERY = gql`
  mutation UpdateCustomer($updateCustomerInput: UpdateCustomerInput!, $customerId: Int!) {
    updateCustomer(updateCustomerInput: $updateCustomerInput, customerId: $customerId) {
      id
      updatedAt
      createdAt
      createdBy
      fullName
    }
  }
`;

export const GET_CUSTOMER_TIMELINE_QUERY = gql`
  query GetCustomerTimeline($orderIn: SortOrder!, $customerId: Int!) {
    getTimeline(orderIn: $orderIn, customerId: $customerId) {
      createdAt
      description
    }
  }
`;

export const GET_LEADS_QUERY = gql`
  query GetLeads($filter: UserFilter, $sort: UserSortInput, $pageSize: Int, $page: Int) {
    getUsers(filter: $filter, sort: $sort, pageSize: $pageSize, page: $page) {
      users {
        id
        firstName
        lastName
        fullName
        email
        createdAt
        lastLogin
      }
      meta {
        currentPage
        isFirstPage
        isLastPage
        nextPage
        pageCount
        previousPage
        totalCount
      }
    }
  }
`;

export const CREATE_ADDRESS_MUTATION = gql`
  mutation CreateAddress($createAddressInput: CreateAddressInput!) {
    createAddress(createAddressInput: $createAddressInput) {
      id
    }
  }
`;

export const UPDATE_ADDRESS_MUTATION = gql`
  mutation UpdateAddress($updateAddressInput: UpdateAddressInput!, $updateAddressId: Int!) {
    updateAddress(updateAddressInput: $updateAddressInput, id: $updateAddressId) {
      id
    }
  }
`;

export const DELETE_ADDRESS_MUTATION = gql`
  mutation DeleteAddress($deleteAddressId: Int!) {
    deleteAddress(id: $deleteAddressId) {
      success
    }
  }
`;

export const GET_COACH_REVIEWS = gql`
  query GetCoachReviews($coachId: Int!, $sort: ReviewSortInput, $pageSize: Int, $page: Int) {
    getCoachReviews(coachId: $coachId, pageSize: $pageSize, page: $page, sort: $sort) {
      reviews {
        id
        comment
        rating
        coachId
        reviewedBy
        createdAt
        updatedAt
        ReviewedBy {
          fullName
          id
        }
      }
      meta {
        currentPage
        totalCount
      }
    }
  }
`;

export const DELETE_COACH_REVIEW = gql`
  mutation DeleteCoachReview($reviewId: Int!) {
    deleteCoachReview(reviewId: $reviewId) {
      success
      reviewId
    }
  }
`;

export const GET_EMPLOYEE_INTERNAL_NOTES = gql`
  query GetEmployeeInternalNotes(
    $pagination: PaginationInput!
    $sort: InternalNotesSortOptions
    $filter: InternalNotesFilterOptions
  ) {
    getInternalNotes(pagination: $pagination, sort: $sort, filter: $filter) {
      results {
        id
        note
        createdAt
        updatedAt
        userId
        Users {
          id
          createdAt
          updatedAt
          fullName
        }
        employeeId
        Employee {
          id
          createdAt
          updatedAt
          fullName
        }
      }
    }
  }
`;

export const GET_INTERNAL_FILES = gql`
  query GetFiles($pageSize: Int, $page: Int, $sort: FilesSort, $filter: FilesFilter) {
    getFiles(pageSize: $pageSize, page: $page, sort: $sort, filter: $filter) {
      files {
        id
        name
        type
        size
        key
        uploadBy
        userId
        agentId
        customerId
        createdAt
        updatedAt
        downloadUrl
        UploadBy {
          fullName
        }
      }
      meta {
        currentPage
        totalCount
      }
    }
  }
`;

export const ASSIGN_COACH_MUTATION = gql`
  mutation AssignCoach($customerId: Int!, $userId: Int!) {
    assignCoach(customerId: $customerId, userId: $userId) {
      id
      userId
      customerId
      role
      Users {
        id
        firstName
        lastName
        email
        fullName
        phoneNumber
        createdAt
        updatedAt
        defaultTab
        talkJsId
        language
        disabled
        emailVerified
        role
        lastLogin
        webPushSubscription
        bio
        profilePicture
        firebaseToken
      }
    }
  }
`;

export const GET_CUSTOMER_INTERNAL_NOTES = gql`
  query GetCustomerInternalNotes($customerId: Int!, $pagination: PaginationInput!) {
    getCustomerInternalNotes(customerId: $customerId, pagination: $pagination) {
      meta {
        currentPage
        isFirstPage
        isLastPage
        previousPage
        nextPage
        pageCount
        totalCount
      }
      results {
        note
        serviceRequestId
        coachVisitId
        userId
        createdAt
        updatedAt
        id
        Users {
          id
          createdAt
          updatedAt
          fullName
        }
      }
    }
  }
`;

export const GET_USER_FULL_NAMES = gql`
  query GetUsersFullNames($talkJsIds: [String]) {
    getUsersFullNames(talkJsIds: $talkJsIds) {
      userId
      fullName
      talkJsId
    }
  }
`;

export const GET_NOTIFICATION_SETTINGS = gql`
  query GetUserNotificationSettingsByUserId($userId: Int!, $notificationType: String!) {
    getUserNotificationSettingsByUserId(userId: $userId, notificationType: $notificationType) {
      id
      userId
      notificationType
      email
      sms
      push
      pushWeb
      createdAt
    }
  }
`;
